import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
// import ClaimarchivesPage from '../modules/report/claimarchive/ClaimarchiveReport'
// import ClaimarchivesPage from '../modules/report/claimarchive/ClaimarchiveReport'


const PrivateRoutes = () => {
  const ServicecentersPage = lazy(() => import('../modules/management/servicecenter-management/ServicecentersPage'))
  const DistributorsPage = lazy(() => import('../modules/management/distributor-management/DistributorsPage'))
  const CustomersupportsPage = lazy(() => import('../modules/management/customersupport-management/CustomersupportsPage'))
  const SupportagentsPage = lazy(() => import('../modules/management/supportagent-management/SupportagentsPage'))
  const CategorymanagementsPage = lazy(() => import('../modules/management/category-management/CategorymanagementsPage'))
  const ProductmanagementsPage = lazy(() => import('../modules/management/product-management/ProductmanagementsPage'))
  const ModelmanagementsPage = lazy(() => import('../modules/management/model-management/ModelmanagementsPage'))
  const PartmanagementsPage = lazy(() => import('../modules/management/part-management/PartmanagementsPage'))
  const CustomermanagementsPage = lazy(() => import('../modules/management/customer-management/CustomermanagementsPage'))
  const TicketmanagementsPage = lazy(() => import('../modules/management/ticket-management/TicketmanagementsPage'))
  const ClaimmanagementsPage = lazy(() => import('../modules/management/claim-management/ClaimmanagementsPage'))
  const ProductreturnmanagementsPage = lazy(() => import('../modules/management/productreturn-management/ProductreturnmanagementsPage'))
  const SendReceiveSparePartsPage = lazy(() => import('../modules/management/sendreceivespareparts-management/SendReceiveSparePartsPage'))
  const SupportticketarchiveReport = lazy(() => import('../modules/report/supportticketarchive/SupportticketarchiveReport'))
  const SentReceivepartsPage = lazy(() => import('../modules/report/sentreceiveparts/SentreceivepartsReport'))
  const ClaimarchivesPage = lazy(() => import('../modules/report/claimarchive/ClaimarchiveReport'))
  const PendingToReceivePartsPage = lazy(() => import('../modules/report/pendingtoreceivefromservicecenter/PendingtoreceivefromservicecenterReport'))
  const SupportticketanalysisReport = lazy(() => import('../modules/report/supportticketsanalysisbytickettype/SupportticketsanalysisReport'))
  const PanelSettingPage = lazy(() => import('../modules/panelsetting/PanelSettingPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}
        <Route path='menu-test' element={<MenuTestPage />} />


        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        /> */}

        <Route
          path='management/customer-support-executive/*'
          element={
            <SuspensedView>
              <ServicecentersPage />
            </SuspensedView>
          }
        />

        {/* <Route
          path='management/distributor-management/*'
          element={
            <SuspensedView>
              <DistributorsPage />
            </SuspensedView>
          }
        /> */}

        {/* <Route
          path='management/customersupport-management/*'
          element={
            <SuspensedView>
              <CustomersupportsPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='management/supportagent-management/*'
          element={
            <SuspensedView>
              <SupportagentsPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='management/category-management/*'
          element={
            <SuspensedView>
              <CategorymanagementsPage />
            </SuspensedView>
          }
        /> */}

{/* <Route
          path='management/model-management/*'
          element={
            <SuspensedView>
              <ModelmanagementsPage />
            </SuspensedView>
          }
        /> */}


        {/* <Route
          path='management/product-management/*'
          element={
            <SuspensedView>
              <ProductmanagementsPage />
            </SuspensedView>
          }
        /> */}

        <Route
          path='management/part-management/*'
          element={
            <SuspensedView>
              <PartmanagementsPage />
            </SuspensedView>
          }
        />

        {/* <Route
          path='crafted/pages/management/CustomerManagement/*' */}
        <Route
          path='management/customer-management/*'
          element={
            <SuspensedView>
              <CustomermanagementsPage />
            </SuspensedView>
          }
        />

        {/* <Route
          path='crafted/pages/management/SupportTicketManagement/*' */}
        <Route
          path='ticket-management/*'
          element={
            <SuspensedView>
              <TicketmanagementsPage />
            </SuspensedView>
          }
        />

        {/* <Route
          path='crafted/pages/management/SendReceiveSparePartsOld/*'
          element={
            <SuspensedView>
              <SendReceiveSparePartsPageOld />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='crafted/pages/management/SendReceiveSpareParts/*' */}
        {/* <Route
          path='sendreceivespareparts-management/*'
          element={
            <SuspensedView>
              <SendReceiveSparePartsPage />
            </SuspensedView>
          }
        /> */}

{/* <Route
          path='claim-management/*'
          element={
            <SuspensedView>
              <ClaimmanagementsPage />
            </SuspensedView>
          }
        /> */}

{/* <Route
          path='productreturn-management/*'
          element={
            <SuspensedView>
              <ProductreturnmanagementsPage />
            </SuspensedView>
          }
        /> */}

        <Route
          path='report/supportticketarchive/*'
          element={
            <SuspensedView>
              <SupportticketarchiveReport />
            </SuspensedView>
          }
        />

        {/* <Route
          path='report/claimarchive/*'
          element={
            <SuspensedView>
              <ClaimarchivesPage />
            </SuspensedView>
          }
        /> */}

        {/* <Route
          path='report/sentreceiveparts/*'
          element={
            <SuspensedView>
              <SentReceivepartsPage />
            </SuspensedView>
          }
        /> */}

        {/* <Route
          path='report/pendingtoreceivefromservicecenter/*'
          element={
            <SuspensedView>
              <PendingToReceivePartsPage />
            </SuspensedView>
          }
        /> */}

        <Route
          path='report/supportticketsanalysisbytickettype/*'
          element={
            <SuspensedView>
              <SupportticketanalysisReport />
            </SuspensedView>
          }
        />

        <Route
          path='panelsetting/*'
          element={
            <SuspensedView>
              <PanelSettingPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
