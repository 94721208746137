import {Card, Col, Row, Spinner} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {adminPremission} from '../../../../utils/role'
import {useEffect, useState} from 'react'
import {apiUrl} from '../../../../apiurl'
import axios from 'axios'
const API_URL = apiUrl
const ALL_STATESTIC_URL = `${API_URL}/support-tickets/support-ticket-statistics`

const DashboardStatistics = () => {
  const [data, setData] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const responceData = await axios.get(ALL_STATESTIC_URL)

      setData(responceData?.data?.responseData ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </div>
    )
  }

  return (
    <>
      {adminPremission() ? (
        <Row className='g-5 g-xl-8 mb-5 mb-xl-10'>
          {data.filter((x:any)=> x.name !== "Pending From Hardware" && x.name !== "Pending From Customer").map((item: any, index: any) => (
            <Col xl={3} key={index}>
              <Card
                className={`bg-body ${
                  item.name === 'Completed'
                    ? 'bg-success'
                    : item.name === 'Rejected'
                    ? 'bg-dark'
                    : item.name === 'New'
                    ? 'bg-info'
                    : item.name === 'Pending From Hardware'
                    ? 'bg-secondary'
                    : item.name === 'Cancelled'
                    ? 'bg-dark'
                    : item.name === 'In Progress'
                    ? 'bg-primary'
                    : item.name === 'Assigned'
                    ? 'bg-danger'
                    : item.name === 'Pending From Customer'
                    ? 'bg-warning'
                    : 'bg-danger'
                } hoverable text-center`}
              >
                <Card.Body>
                  {item.name === 'Completed' ? (
                    <i className='las la-check-circle fs-3x text-white'></i>
                  ) : item.name === 'New' ? (
                    <i className='las la-ticket-alt fs-3x'></i>
                  ) : item.name === 'Pending From Hardware' ? (
                    <i className='las la-tv fs-3x text-black'></i>
                  ) : item.name === 'Cancelled' ? (
                    <i className='las la-times-circle fs-3x text-white'></i>
                  ) : item.name === 'In Progress' ? (
                    <i className='las la-hourglass-half fs-3x text-white'></i>
                  ) : item.name === 'Assigned' ? (
                    <i className='las la-user fs-3x text-white'></i>
                  ) : item.name === 'Pending From Customer' ? (
                    <i className='las la-users fs-3x text-white'></i>
                  ) : item.name === 'Rejected' ? (
                    <i className='las la-ban fs-3x text-white'></i>
                  ) : (
                    <i className='las la-ban fs-3x text-white'></i>
                  )}
                  <h1
                    className={`${
                      item.name === 'Completed' ||
                      item.name === 'New' ||
                      item.name === 'Rejected' ||
                      item.name === 'Cancelled' ||
                      item.name === 'In Progress' ||
                      item.name === 'Assigned' ||
                      item.name === 'Pending From Customer' ||
                      item.name === 'Rejected'
                        ? 'text-white'
                        : item.name === 'Pending From Hardware'
                        ? 'text-black'
                        : 'text-gray-900'
                    } fw-bold mb-2 mt-2 display-6`}
                  >
                    {item.count}
                  </h1>
                  <div
                    className={`fw-semibold ${
                      item.name === 'Completed' ||
                      item.name === 'New' ||
                      item.name === 'Cancelled' ||
                      item.name === 'In Progress' ||
                      item.name === 'Assigned' ||
                      item.name === 'Pending From Customer' ||
                      item.name === 'Rejected'
                        ? 'text-white'
                        : item.name === 'Pending From Hardware'
                        ? 'text-black'
                        : 'text-gray-400'
                    }`}
                  >
                    {item.name}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <Row className='g-5 g-xl-8 mb-5 mb-xl-10'>
          <Col xl={3}>
            <Card className='bg-body bg-dark hoverable text-center'>
              <Card.Body>
                <i className='las la-ticket-alt fs-3x text-white'></i>
                <h1 className='text-white fw-bold mb-2 mt-2 display-6'>
                  {data[0]?.total_tickets ? data[0]?.total_tickets : '-'}{' '}
                </h1>
                <div className='fw-semibold text-white'>Total Tickets</div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className='bg-body bg-success hoverable text-center'>
              <Card.Body>
                <i className='lar la-check-circle  fs-3x text-white'></i>
                <h1 className='text-white fw-bold mb-2 mt-2 display-6'>
                  {data[0]?.completed_tickets ? data[0]?.completed_tickets : '-'}{' '}
                </h1>
                <div className='fw-semibold text-white'>Completed Tickets</div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className='bg-body bg-warning hoverable text-center'>
              <Card.Body>
                <i className='las la-tv fs-3x text-white'></i>
                <h1 className='text-white fw-bold mb-2 mt-2 display-6'>
                  {data[0]?.pending_tickets ? data[0]?.pending_tickets : '-'}
                </h1>
                <div className='fw-semibold text-white'>Pending Tickets</div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className='bg-body bg-danger hoverable text-center'>
              <Card.Body>
                <i className='las la-clock fs-3x text-white '></i>
                <h1 className='text-white fw-bold mb-2 mt-2 display-6'>
                  {data[0]?.delayed_tickets ? data[0]?.delayed_tickets : '-'}
                </h1>
                <div className='fw-semibold text-white'>Delayed Tickets</div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  )
}

export {DashboardStatistics}
